<template>
  <div :class="$style.add">
    <div id="edit" :class="$style.base">
      <p :class="$style.title">基础信息</p>
      <div :class="$style.box">
        <div :class="$style.left">
          <span class="required">*</span>
          资讯标题
        </div>
        <div :class="$style.right">
          <BjInput v-model="add.title" placeholder="请输入资讯标题" />
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.left">
          <span class="required">*</span>
          资讯封面
        </div>
        <div :class="$style.right">
          <selectMaterial :data="add.cover" :allow="['image']" @change="coverChange" />
          <div class="tip mt-10">建议尺寸214px*160px；大小不超过2M；格式支持JPG、PNG。</div>
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.left">
          <span class="required">*</span>
          所属分组
        </div>
        <div :class="$style.right">
          <BjSelect
            v-model="add.group_id"
            key-field="id"
            placeholder="请选择分组"
            value-field="id"
            label-field="name"
            show-all
            :options="groupData"
          />
        </div>
      </div>

      <div :class="$style.box">
        <div :class="$style.left">
          <span class="required">*</span>
          是否置顶
        </div>
        <div :class="$style.right" class="line-40">
          <a-radio-group v-model="add.is_top">
            <a-radio :value="0"> 不置顶 </a-radio>
            <a-radio :value="1"> 置顶 </a-radio>
          </a-radio-group>
        </div>
      </div>

      <div :class="$style.box">
        <div :class="$style.left">
          <span class="required">*</span>
          发布状态
        </div>
        <div :class="$style.right" class="line-40">
          <a-radio-group v-model="add.status">
            <a-radio :value="1"> 已发布 </a-radio>
            <a-radio :value="2"> 未发布 </a-radio>
          </a-radio-group>
        </div>
      </div>
    </div>
    <div :class="$style.edit">
      <p :class="$style.title">资讯内容</p>
      <BjEditor ref="editor" v-model="add.content" placeholder="请在这里输入新闻资讯的详细信息。" />
    </div>
    <div :class="$style.footer" :style="{ width: footWidth }">
      <BjButton v-permission="'app.news.add-edit'" class="mr-10" type="primary" @click="onSave()">
        <i class="ri-send-plane-2-line left" />
        立即保存
      </BjButton>
      <BjButton @click="onCancel()"> 取消保存 </BjButton>
    </div>
  </div>
</template>

<script>
import BjEditor from '@/components/BjEditor'
import selectMaterial from '@/components/selectMaterial'
import { newsService } from '@/service'

const service = new newsService()

export default {
  name: 'add',
  components: {
    selectMaterial,
    BjEditor,
  },
  data() {
    return {
      add: {
        title: null,
        cover: {},
        group_id: [],
        status: 1,
        content: null,
        is_top: 0,
      },
      footWidth: '800px',
      groupData: [],
    }
  },
  created() {
    this.getGroup()
    if (this.$route.query.id) {
      this.getInfo()
    } else {
      this.initAdd()
    }
  },
  mounted() {
    this.footWidth = document.getElementById('edit').offsetWidth + 'px'
    window.onresize = () => {
      this.footWidth = document.getElementById('edit').offsetWidth + 'px'
    }
  },
  methods: {
    async getGroup() {
      const { data } = await service.groupList()
      this.groupData = data
    },
    async getInfo() {
      try {
        const { data } = await service.getInfo({
          id: this.$route.query.id,
        })
        this.add = data
      } catch (e) {}
    },
    async onSave() {
      try {
        this.$route.query.id
          ? await service.edit({
              ...this.add,
              group_id: Array.isArray(this.add.group_id) ? '' : this.add.group_id,
            })
          : await service.save({
              ...this.add,
              group_id: Array.isArray(this.add.group_id) ? '' : this.add.group_id,
            })
        this.$message.success(this.$route.query.id ? '修改成功' : '添加成功')
        this.$router.push({
          name: 'newsManage',
        })
      } catch (e) {}
    },
    onCancel() {
      this.$router.push({
        name: 'newsManage',
      })
    },
    coverChange(data) {
      this.add.cover = data[0]
    },
    initAdd() {
      this.add = {
        title: null,
        cover: {},
        group_id: [],
        status: 1,
        content: null,
        is_top: 0,
      }
    },
  },
}
</script>

<style lang="less" module>
.add {
  .base {
    padding: 20px;
    background: #fff;
  }

  .title {
    color: #000;
    font-size: 16px;
  }

  .box {
    display: flex;
    margin-top: 20px;

    .left {
      width: 140px;
      line-height: 40px;
    }

    .right {
      width: 600px;
    }
  }

  .edit {
    margin-top: 20px;
    margin-bottom: 60px;
    padding: 20px;
    background: #fff;
  }

  .footer {
    position: fixed;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    line-height: 60px;
    background: #fff;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
  }
}
</style>
